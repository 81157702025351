import Button from '@components/Button';
import { ButtonsType } from '@types';
import cx from 'classnames';

import styles from './styles.module.scss';

const Buttons: React.FC<ButtonsType & { className?: string }> = ({
  className,
  items
}) => (
  <div className={cx(styles.wrap, className)}>
    {items.map(button => (
      <Button
        key={button._key}
        href={button.href}
        variant={button.variant}
        video={button.video}
        aria-label={button.label}
      >
        {button.label}
      </Button>
    ))}
  </div>
);

export default Buttons;

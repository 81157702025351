import Text from '@components/atoms/Text';
import Title from '@components/atoms/Title';
import Buttons from '@components/Buttons';
import { Column, Columns } from '@components/website/Columns';
import { ModuleCtaType } from '@types';

import { ModuleComponentType } from '../../../../types/module';
import SectionWrap from '../ModuleSection';
import styles from './ModuleCta.module.scss';

const ModuleCta: ModuleComponentType = ({ data }) => {
  const { title, description, buttons } = data as unknown as ModuleCtaType;

  return (
    <SectionWrap className={styles.wrap}>
      <Columns>
        <Column start={0} span={12}>
          <Title className={styles.title} variant="h3" as="h2">
            {title}
          </Title>
          <Text variant="b1">{description}</Text>

          <Buttons className={styles.buttons} {...buttons} />
        </Column>
      </Columns>
    </SectionWrap>
  );
};

export default ModuleCta;
